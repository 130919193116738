import Contact from "./components/contact/Contact";
import About from "./components/about/About";

import './App.css';

function App() {
  return (
    <>
      <About />
      <Contact />
    </>
  );
}

export default App;
